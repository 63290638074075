import { Component, OnInit, ViewChild, Inject, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatDialog, MAT_DIALOG_DATA, MatDatepickerInputEvent }   from '@angular/material';
import { OperationDialogComponent } from './operation-dialog/operation-dialog.component';
import { ExchangeDialogComponent } from './exchange-dialog/exchange-dialog.component';
import { ExchangeEditDialogComponent } from './exchange-edit-dialog/exchange-edit-dialog.component';
import { HolidayDialogComponent } from './holiday-dialog/holiday-dialog.component';
import { SpotDialogComponent } from './spot-dialog/spot-dialog.component';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { BaseComponent } from '../base.component';
import { StaffDetailDialogComponent } from '../staffs/staff-detail-dialog/staff-detail-dialog.component';

export interface calendar {
  nameSite: string;
  rank: string;
  name: string;
  days: any;
  numberMobilePhone: string;
  holiday: string;
  dust: string;
  memo: string;
}

@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.less']
})
export class OperationComponent extends BaseComponent implements OnInit {
  // 清掃員一覧テーブルのデータソース
  public dataSource = new MatTableDataSource();

  // エクスポート用データ
  public exportData = [];

  // 清掃員一覧の全レコードの件数
  dataSourceLength = 0;

  nowLoading1 = true;
  nowLoading2 = true;

  // エクスポート用ローディング
  public loadingExport = false;

  public keyword = "";

  public filterName = "";

  public filterNameKana = "";

  public nameSalesOffice = "";

  public areas = [];

  public areaIds = "";

  public siteName = "";

  public hasHoliday = true;

  public arrangement = false;

  toBeArrangement = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild('TABLE') table: ElementRef;

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  public columnDefsInit = [
    'nameSite',
    'rank',
    'name',
    'holiday',
    'dust',
    'memo'
  ];
  public columnDefs = [];

  public dayList = [];
  public dayColumnDefList = [];

  public day1: FormControl;
  public endDay: FormControl;
  public countDay: number;

  public holidaysCount: number[][];

  dayOfWeek = ['日', '月', '火', '水', '木', '金', '土'];

  public sDate: string;
  public sEndDate: string;


  public statusChoices = [
    {key: "all", value: "全て"},
    {key: "unknown", value: "未登録"},
    {key: "alreadyRegistered", value: "登録済み"},
    {key: "ok", value: "●：代勤OK"},
    {key: "ng", value: "×：代勤行けない"},
    {key: "noContact", value: "×：連絡とれず"},
    {key: "request", value: "復帰確認"},
    {key: "cantReturn", value: "復帰不可・連絡取れず"},
    {key: "holiday", value: "休み取り消し"},
    {key: "arrangement", value: "要手配"}
  ];

  public status = this.statusChoices[0]['value'];

  public sortChoices = [
    {key: "", value: "契約"},
    {key: "cd_site", value: "物件"},
    {key: "staff_daily_cleaning", value: "清掃員"}
  ];

  public sort = this.sortChoices[0]['key'];

  public showShift: boolean = true;
  
  // 「範囲指定」チェックボックス
  public rangeSpecified: boolean = false;

  // 初期表示
  init = true;
  
  // 改行（エクスポートで見やすくするため）
  br = '\r\n';

  // 表示するデータ
  public list = [];

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private dialog1: MatDialog,
    private dialog2: MatDialog,
    private dialog3: MatDialog,
    private dialog4: MatDialog,
    private dialog5: MatDialog,
    private dialog6: MatDialog,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone)  {
    super();
  }

  ngOnInit() {
    // 「有休5日使用義務」から遷移した場合は、手配ありのチェックを外す
    let hasHolidayCheck = this.route.snapshot.paramMap.get('hasHoliday');
    if (hasHolidayCheck == 'false') {
      this.hasHoliday = false;
    }

    this.day1 = new FormControl(new Date());
    this.endDay = new FormControl(new Date(this.day1.value.getFullYear(), this.day1.value.getMonth(), this.day1.value.getDate()+6));
    this.sDate = this.day1.value.getFullYear() + "-" + (this.day1.value.getMonth()+1) + "-" + this.day1.value.getDate();
    this.sEndDate = this.endDay.value.getFullYear() + "-" + (this.endDay.value.getMonth()+1) + "-" + this.endDay.value.getDate();
    this.countDay = 0;
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
    * ページ読み込み後の初期化メソッド
    */
  ngAfterViewInit() {
    this.filterName =
      localStorage.getItem('search_staffs_name') != null ? localStorage.getItem('search_staffs_name') : "";
    this.filterNameKana =
      localStorage.getItem('search_staffs_nameKana') != null ? localStorage.getItem('search_staffs_nameKana') : "";
    this.siteName =
      localStorage.getItem('search_site_name') != null ? localStorage.getItem('search_site_name') : "";
    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;

    this.getAreas();


    this.dataSource.paginator = this.paginator;
    this.dialog1.afterAllClosed.subscribe(res => {
      if (!this.init) {
        this.getOperations(this.paginator.pageIndex, this.paginator.pageSize);  
      }
    });
    this.dialog2.afterAllClosed.subscribe(res => {
      if (!this.init) {
        this.getOperations(this.paginator.pageIndex, this.paginator.pageSize);  
      }
    });

    this.paginator.page.subscribe(() => {
      if (!this.init) {
        this.getOperations(this.paginator.pageIndex, this.paginator.pageSize); 
      }
    });
    this.getOperations(this.paginator.pageIndex, this.paginator.pageSize);
    this.cdr.detectChanges();
    this.init = false;

  }

  applyFilter(keyword: string, event?: boolean) {
    if(event)
      return;
    this.paginator.pageIndex = 0;
    this.getOperations(this.paginator.pageIndex, this.paginator.pageSize);
  }

  /**
   * 休み削除
   * @param row データ
   * @param day 日付
   * @param type 休み種別
   * @param time 時間帯
   * @param idx インデックス
   */
  removeHoliday(row: any, day: Date, type: number, time: string, idx: number) {
    if (!this.removeConfirm(row, day, idx, false)) {
      return
    }
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-LoginAccessKey": localStorage.getItem("accessKey")
      })
    };
    let status = {
      sequenceNumber: row.sequenceNumber,
      cdSite: row.cdSite,
      staffDailyCleaning: row.staffDailyCleaning,
      day: day.getFullYear() + "-" + ("00" + (day.getMonth()+1)).slice(-2) + "-" + ("00" + day.getDate()).slice(-2),
      status: type,
      note: ""
    };

    this.http.post(
      API_BASE_URL + "/api/v1/status/remove?nameSite=" + row.nameSite + "&name=" + row.name + "&time=" + time, [status], httpOptions
    ).subscribe(res => {
      console.info("削除");
      this.applyFilter("", false);
    },error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 休み一括削除
   * @param row データ
   * @param day 日付
   * @param type 休み種別
   * @param idx インデックス
   */
  bulkRemoveHoliday(row: any, day: Date, type: number, idx: number) {
    if (!this.removeConfirm(row, day, idx, true)) {
      return
    }
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-LoginAccessKey": localStorage.getItem("accessKey")
      })
    };
    let status = {
      sequenceNumber: row.sequenceNumber,
      cdSite: row.cdSite,
      staffDailyCleaning: row.staffDailyCleaning,
      day: day.getFullYear() + "-" + ("00" + (day.getMonth()+1)).slice(-2) + "-" + ("00" + day.getDate()).slice(-2),
      status: type,
      note: ""
    };
    this.http.post(
      API_BASE_URL + "/api/v1/status/bulkremove?nameSite=" + row.nameSite + "&name=" + row.name, [status], httpOptions
    ).subscribe(res => {
      console.info("削除");
      this.applyFilter("", false);
    },error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 休み削除処理の確認
   * @param row データ
   * @param day 日付
   * @param idx インデックス
   * @param isBulk 一括削除か否か
   * @returns 
   */
  removeConfirm(row: any, day: Date, idx: number, isBulk: boolean) {
    let result = false
    let name = ''
    if (row.name && row.name != '') {
      name = row.name.replaceAll(/\s+/g, '') + 'さん'
    }
    const d = moment(day).format('YYYY/MM/DD')
    // 1日のみの休み削除
    if (!isBulk) {
      if (row.statusArray[idx]) {
        if (name != '') {
          name += 'の'
        }
        result = window.confirm(name + d + 'の休みを削除してよろしいでしょうか？')
      } else {
        if (name != '') {
          name += 'は'
        }
        result = window.confirm(name + d + 'に休み登録をしていませんが、削除処理を実行しますか？')
      }

    // 休み一括削除
    } else {
        if (name != '') {
          name += 'の'
        }
      result = window.confirm(name + d + 'からの休み登録を全て削除してよろしいでしょうか？')
    }
    return result
  }

  /**
   * 休み登録している日のセル色付け
   * @param day 
   * @param absence 
   * @returns 
   */
  fillColor(absence: any, row, index) {

    // 振替元
    if (absence && absence.status == 7) {
      let difftime = 0
      if (row.exchangeDiffTime[index]) {
        difftime = Number(row.exchangeDiffTime[index])
      }
      // 振替元の移した時間を足しても契約を満たしていない
      if (difftime < 0) {
        return "notAssigned";
      } else {
        return "ok";
      }
    }

    // 見せかけの欠員
    if (row.tentative[index] == '1') {
      return "notAssigned";
    }

    // 指定有休登録しており、もともと勤務日でない日はセルを青くする
    if (absence && absence.status == 6 && row.workingTime[index] == '-') {
      return "ok";

    // 振替指定日＆代勤未登録
    } else if (row.exchangeDay[index] != 'NULL' && !row.days[this.getDayKey(this.dayList[index])].length) {
      return "notAssigned";

    // 振替元
    // } else if (absence && absence.status == 7) {
    //   // 振替元の移した時間を足しても契約を満たしていない
    //   if (row.workInsteadShortTime[index] == '1' ) {
    //     return "notAssigned";
    //   } else {
    //     return "ok";
    //   }
    }

    // 通常清掃員が休み登録している
    if (absence) {
      return "notAssigned";
    }
    return null;
  }

  /**
   * ステータスフィルタに色付け
   * @param status ステータス
   * @returns 
   */
  statusColor(status: string) {
    switch(status) {
      case '全て': return "drop-all";
      case '未登録': return "drop-unknown";
      case '登録済み': return "drop-alreadyRegistered";
      case '●：代勤OK': return "drop-ok";
      case '×：代勤行けない': return "drop-ng";
      case '×：連絡とれず'  : return "drop-ngContact";
      case '復帰確認': return "drop-request";
      case '復帰不可・連絡取れず': return "drop-cantReturn";
      case '休み取り消し': return "drop-holiday";
    }
  }

  /**
   * 代勤セルに色付け
   * @param cell セル情報
   * @param workInsteadShortTime 代勤Okで登録しているが時間を満たしていない：1
   * @returns 
   */
  workInsteadColor(cell, workInsteadShortTime) {
    switch(cell.workInsteadStatus) {
      case '未登録': return "drop-unknown";
      case '登録済み': return "drop-alreadyRegistered";
      case '●：代勤OK':
        if (workInsteadShortTime == '1' ) {
          return "drop-unknown";
        } else {
          return "drop-ok";
        }
      case '×：代勤行けない': return "drop-ng";
      case '×：連絡とれず'  : return "drop-ngContact";
      case '復帰確認': return "drop-request";
      case '復帰不可・連絡取れず': return "drop-cantReturn";
      case '休み取り消し': return "drop-holiday";
      case 'スポット作業': return "spot";
    }
  }

  /**
   * 表示開始日
   * @param event 
   */
  changeDate(event: MatDatepickerInputEvent<Date>) {
    this.day1 = new FormControl(event.value);
    this.sDate = this.day1.value.getFullYear() + "-" + ("00"+(this.day1.value.getMonth()+1)).slice(-2) + "-" + ("00" + this.day1.value.getDate()).slice(-2);
    if (!this.rangeSpecified) {
      this.endDay = new FormControl(new Date(this.day1.value.getFullYear(), this.day1.value.getMonth(), this.day1.value.getDate()+6));
      this.sEndDate = moment(this.endDay.value).format('YYYY-MM-DD');
    }
    this.applyFilter(this.keyword, false);
  }

  /**
   * 表示終了日
   * @param event 
   */
  changeEndDate(event: MatDatepickerInputEvent<Date>) {
    this.endDay = new FormControl(event.value);
    this.sEndDate = this.endDay.value.getFullYear() + "-" + ("00"+(this.endDay.value.getMonth()+1)).slice(-2) + "-" + ("00" + this.endDay.value.getDate()).slice(-2);
    this.applyFilter(this.keyword, false);
  }

  /**
    * 稼働予定一覧を取得します
    * @param index 開始インデックス
    * @param size  取得サイズ
    */
  getOperations(index: number, size: number) {
    localStorage.setItem('search_staffs_name', this.filterName);
    localStorage.setItem('search_staffs_nameKana', this.filterNameKana);
    localStorage.setItem('search_site_name', this.siteName);

    this.nowLoading1 = true;
    this.http.get(
      API_BASE_URL + '/api/v1/contractDetail/operationManagement?s=0&e=8000&index=' + index
            + "&size=" + size
            + "&filterName=" + encodeURIComponent(this.filterName)
            + "&filterNameKana=" + encodeURIComponent(this.filterNameKana)
            + "&siteName=" + encodeURIComponent(this.siteName)
            + "&areasStr=" + this.areaIds
            + "&startDate=" + this.sDate
            + "&endDate=" + this.sEndDate
            + "&status=" + this.status
            + "&hasHoliday=" + this.hasHoliday
            + "&sort=" + this.sort
            + "&toBeArrangement=" + this.toBeArrangement
            + "&arrangement=" + this.arrangement,
      {headers: this.headers}
    )
    .subscribe(res => {
      let calendars:calendar[] = res as calendar[];
      this.createColumnDefs(calendars);
      this.dataSource = new MatTableDataSource(this.createTableData(calendars));

      if (res != null) {
        this.dataSourceLength = res[0].totalSize;
        this.holidaysCount = res[0].holidayCount as number[][];
        this.list = res as []
      } else {
        this.dataSourceLength = 0;
      }
      this.nowLoading1 = false;
      this.sDate = this.day1.value.getFullYear() + "-" + (this.day1.value.getMonth()+1) + "-" + this.day1.value.getDate();
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }
  
  /**
    * エクスポート用に全件取得
    */
  getAllOperations() {
    this.loadingExport = true;
    this.http.get(
      API_BASE_URL + '/api/v1/contractDetail/allOperationManagement?s=0&e=8000'
            + "&filterName=" + encodeURIComponent(this.filterName)
            + "&filterNameKana=" + encodeURIComponent(this.filterNameKana)
            + "&siteName=" + encodeURIComponent(this.siteName)
            + "&areasStr=" + this.areaIds
            + "&startDate=" + this.sDate
            + "&endDate=" + this.sEndDate
            + "&status=" + this.status
            + "&hasHoliday=" + this.hasHoliday
            + "&sort=" + this.sort
            + "&toBeArrangement=" + this.toBeArrangement
            + "&arrangement=" + this.arrangement,
      {headers: this.headers}
    )
    .subscribe(res => {
      let allData = [];
      let days = [];
      this.exportData = [];

      if (res != null) {
        allData =  res as calendar[];

        // 日付のカラム
        for (let i = 0; i < this.dayList.length; i++) {
          const d = this.dayList[i];
          let holi = `休${this.getHolidayCount(i,0)}/有${this.getHolidayCount(i,1)}/欠${this.getHolidayCount(i,2)}`
          let week = moment(d).format('d');
          let w = ''
          switch (week) {
            case '0':
              w = '(日)';
              break;
            case '1':
              w = '(月)';
              break;
            case '2':
              w = '(火)';
              break;
            case '3':
              w = '(水))';
              break;
            case '4':
              w = '(木)';
              break;
            case '5':
              w = '(金)';
              break;
            case '6':
              w = '(土)';
              break;
          
            default:
              break;
          }

          days.push(moment(d).format('YYYY/M/D') + w + ' ' + holi);
        }
        
        for (let i = 0; i < allData.length; i++) {
          let target = {}
          const a = allData[i];
          let name = ''
          if (a['name']) {
            name = a['name'];
          }
          let mobile = ''
          if (a['numberMobilePhone']) {
            mobile = a['numberMobilePhone'];
          }
          let tel = ''
          if (a['numberPhone']) {
            tel = a['numberPhone'];
          }
          let seq = '';
          if (a['sequenceNumber'] && a['sequenceNumber'] != '') {
            seq = this.setSequence(a['sequenceNumber']);
          }

          target = {
              '現場名': a['nameSite']
            , 'ランク': a['rank']
            , '通常清掃員': name + '\r\n' + mobile + '\r\n' +tel + '\r\n' + seq
          }

          for (let j = 0; j < days.length; j++) {
            const day = days[j];
            // 休みの表示
            let holi =this.setHolidayType(a.statusArray[j], a.tentative[j], a.exchangeDay[j]);
            // 代勤者
            let ins = a.days[this.getDayKey(this.dayList[j])]
            let instead = ''
            for (let k = 0; k < ins.length; k++) {
              const i = ins[k];
              let tel = i.workInsteadNumberMobilePhone || i.workInsteadNumberPhone;
              if (tel && tel != '') {
                tel = ' ' + tel;
              } else {
                tel = '';
              }
              instead += '\r\n 【代】（' + i.workInsteadWorkingTime + '）' + i.workInsteadName + tel
            }
            target[day] = this.getTimes(a.workingTime[j], a.exchangeDay[j]) + ' ' + holi + instead;
          }

          target['祝日'] = a.kubunHoliday;
          target['祝日ゴミ'] = a.hasCarryingoutGarbage;
          this.exportData.push(target);
        }
      }
      this.loadingExport = false;

      this.export();
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 休み区分
   * @param value 休みデータ
   * @param tentative 見せかけの欠員
   * @param exchangeDay 振替
   * @param idx インデックス
   * @returns 
   */
  setHolidayType(value, tentative, exchangeDay) {
    if (value && value['status']) {
      let s = value['status'];
      if (s == 0) {
        return '休み';
      } else if (s == 1) {
        return '有休';
      } else if (s == 2) {
        return '欠員';
      } else if (s == 5) {
        return '要確認';
      } else if (s == 6) {
        return '指定有休';
      } else if (s == 7) {
        return '振替';
      }
    }
    // 見せかけの欠員
    if (tentative && tentative == '1') {
      return '欠員';
    }
    // 振替指定日
    if (exchangeDay != 'NULL') {
      return '振替指定日';
    }
    return '';
  }

  /**
   * 休み登録ダイアログ
   * @param row 
   * @param day 
   * @param target 
   * @param status 
   */
  openHolidayDialog(row: any, day: Date, target: number, status: any) {
    let d = new Date();
    d.setTime(day.getTime());
    this.dialog1.open(HolidayDialogComponent, {
      data: {
        data: row,
        day: d,
        target: target,
        status: status
      }
    });
  }

  /**
   * 代勤ダイアログ
   * @param row 
   * @param day 
   * @param target 
   */
  openDialog(row: any, day: Date, target: number) {
    this.dialog2.open(OperationDialogComponent, {
      data: {
        data: row,
        day: day,
        target: target
      }
    });
  }

  /**
   * 振替ダイアログ
   * @param row 
   * @param day 
   * @param target 
   * @param status 
   */
  openExchange(row: any, day: Date, target: number, status: any) {
    this.dialog3.open(ExchangeDialogComponent, {
      data: {
        data: row,
        day: day,
        target: target,
        status: status
      }
    });
  }

  /**
   * 振替指定日編集ダイアログ
   * @param row 行データ
   * @param day 対象日
   * @param target 対象列インデックス
   */
  openExchange2(row: any, day: Date, target: number) {
    this.dialog6.open(ExchangeEditDialogComponent, {
      data: {
        data: row,
        day: day,
        target: target
      }
    });
  }

  /**
   * スポット登録ダイアログ
   * @param row 
   * @param day 
   * @param target 
   */
  openSpotDialog(row: any, day: Date, target: number) {
    this.dialog5.open(SpotDialogComponent, {
      data: {
        data: row,
        day: day,
        target: target
      }
    });
  }

  /**
   * エリアドロップダウン作成
   */
  getAreas() {
    this.nowLoading2 = true;
    this.http.get(
      API_BASE_URL + "/api/v1/area", {headers: this.headers}
    ).subscribe(res => {
      this.areas = res as any[];
      this.nowLoading2 = false;
    }, error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * エクスポートボタンイベント
   */
  exportBtn() {
    this.getAllOperations();
  }

  /**
   * エクスポート実行
   */
  export() {
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, '稼働管理.xlsx');
  }

  /**
   * ColumnDefsを生成
   */
  createColumnDefs(calendars : calendar[]) {
    let title1 = this.columnDefsInit.slice(0, 3);
    let title2 = [];
    let title3 = this.columnDefsInit.slice(3, 5);

    if(calendars && calendars.length > 0) {
      let daysCount = Object.keys(calendars[0].days).length;

      for (let idx = 0; idx < daysCount; idx++) {
        title2.push('day' + (idx+1));
      }
    }

    this.dayColumnDefList = title2.slice(0, title2.length);
    this.columnDefs = title1.concat(title2).concat(title3);
  }

  /**
   * 表示するテーブル用のデータ生成
   */
  createTableData(calendars : calendar[]) {
    let list = [];
    this.dayList = null;
    if(calendars && calendars.length > 0) {

      calendars.forEach(staff => {
        let row = staff;

        let keys: string[] = Object.keys(staff.days);
        let values = Object.values(staff.days);
        let idx = 0;
        values.forEach( value => {
          idx++;
          row['day' + (idx)] = value;
        });

        if (this.dayList == null) {
          this.dayList = [];
          keys.forEach( key => {
            this.dayList.push(new Date(Date.parse(key)));
          });
        }
        list.push(row);
      });
      return list;
    }
  }

  /**
   * 休み・有休・欠員の登録数を日ごとに表示
   * @param index 表示番号
   * @param type 1：休み、6：指定有休、2：欠員、3：合計
   * @returns 
   */
  getHolidayCount(index : number, type : number) {
    let result = 0;
      if (this.holidaysCount) {
        if (type != 3) {
          result = this.holidaysCount[index][type];
        } else {
          result = this.holidaysCount[index][0] + this.holidaysCount[index][1] + this.holidaysCount[index][2];
        }
      }
      return result;
  }

  /**
   * 日付のMatColumnDefを取得する。
   */
  getDayMatColumnDef(idx: number) {
    let ret = 'day';
    ret += idx++; 
    return ret;
  }

  /**
   * 日付のキーを取得する。(daysのプロパティ用)
   */
  getDayKey(day : Date) {
    return this.datePipe.transform(day, "yyyy-MM-dd");
  }

  /**
   * 休み削除権限
   */
  getDeleteHolidayAuth() {
    return localStorage.getItem("deleteHoliday");
  }

  /**
   * スポット登録権限
   */
  getSpotAuth() {
    return localStorage.getItem("spot");
  }

  /**
   * 「要手配」チェックボックスチェンジイベント
   */
  changeToBeArrangement() {
    if (this.toBeArrangement) {
      this.day1 = new FormControl(new Date(moment().format('YYYY/MM/DD')));
      this.sDate = moment(this.day1.value).format('YYYY-MM-DD');
      this.endDay = new FormControl(new Date(moment().add(2, 'days').format('YYYY/MM/DD')));
      this.sEndDate = moment(this.endDay.value).format('YYYY-MM-DD');

      // 「手配あり」のチェックを外す
      this.hasHoliday = false;
    } else {      
      this.endDay = new FormControl(new Date(moment().add(6, 'days').format('YYYY/MM/DD')));
      this.sEndDate = moment(this.endDay.value).format('YYYY-MM-DD');
    }
    this.applyFilter(this.keyword, false);
  }

  /**
   * 代勤者のborder-bottom
   * @param length 代勤者数
   * @param index 表示順
   */
  makeBorder(length: number, index:number) {
    if (index < length-1) {
      return 'work-instead-border';
    }
  }

  /**
   * 行の背景色
   * @param index 行番号
   * @returns 
   */
  backgroundEven(index: number) {
    if (index % 2 == 1) {
      return 'light-blue-back';
    } else {
      return 'white-back';
    }
  }

  /**
   * 休み表示帯の幅
   * @param holiday 
   * @returns 
   */
  checkHoliday(holiday: any, length: number) {
    if(holiday != null && length > 1) {
      return "holiday-margin";
    }
  }

  /**
   * 代勤者セルの高さ
   * @param row 1行の物件データ
   * @returns 
   */
  changeHeight(row: any) {

    let workInsteadLength = 0;

    for (let i = 0; i < this.dayList.length; i++) {
      let length = row.days[this.getDayKey(this.dayList[i])].length;
      if (workInsteadLength < length) {
        workInsteadLength = length;
      }
    }
    if (workInsteadLength == 1) {
      return 'work-instead-height1';
    } else {
      return 'work-instead-height2';
    }
  }

  /**
   * 文言
   * @param show 本来表示：1、本来表示されない：0
   * @returns 
   */
  disp(show) {
    if (show == '0') {
      return '本来表示されません\r\n'
    }
  }

  /**
   * ツールチップに詳細を表示
   * @param row 行
   * @returns 
   */
  detailTip(row) {
    let cd = row.cdContract
    if (cd == '' || cd == '-1') {
      cd = 'なし'
    }
    let end = row.workingEnd
    if (end == '9999-12-31') {
      end = ''
    } else {
      end = ', 作業終了：' + end
    }
    return '物件コード：' + row.cdSite + ', 契約コード：' + cd + end
  }

  /**
   * シーケンス表示
   * @param seq シーケンス
   */
  setSequence(seq) {
    let s = seq
    if (s.indexOf('-') != -1) {
      return '(欠員物件)'
    } else {
      return '(No：' + s + ')'
    }
  }

  /**
   * 欠員表示_欠員物件行は見せかけの欠員登録を表示
   * @param workTime 勤務時間帯
   * @param index 列数
   */
  setVacantWidth(workTime, index) {

    // その日が勤務予定
    if (workTime[index] != '-') {
      // 前日が勤務予定日なら-1を返す
      if (workTime[index-1] && workTime[index-1] != '-') {
        return -1

      // 前日がない
      } else {
        let count = 0;
        for (let i = index; i < workTime.length; i++) {
          if (workTime[i] != '-') {
            count++;
          } else {
            break;
          }
        }
        return count * 125 -1;
      }

    // その日が勤務予定なし
    } else {
      return -1
    }
  }

  /**
   * 振替指定日の帯の長さ
   * @param ex 振替実働時間
   * @param index インデックス
   * @returns 
   */
  setExcahngeWidth(ex, index) {
    // 当日に振替指定日がある
    if (ex[index] != 'NULL') {
      let count = 0;
      // 前日にも振替指定日がある
      if (ex[index-1] && ex[index-1] != 'NULL') {
        return -1;
      
      // 前日がない
      } else {
        for (let i = index; i < ex.length; i++) {
          if (ex[i] != 'NULL') {
            count++;
          } else {
            break;
          }
        }
        return count * 125 - 1;
      }
    // 当日に振替指定日がない
    } else {
      return -1
    }
  }

  /**
   * 表示する時間帯
   * @param workingTime 実働時間帯
   * @param exchangeDay 振替先の実働時間
   * @returns 
   */
  getTimes(workingTime, exchangeDay) {
    if (exchangeDay != 'NULL') {
      return workingTime + '\r\n(+ ' + exchangeDay + '時間)'
    } else {
      return workingTime
    }
  }

  setTooltip(holiday, note) {
    if (note == '') {
      return holiday;
    }
  }

  /**
   * 契約詳細情報の実働時間のツールチップ
   * 
   * @param time 実働時間
   */
  timeTooitip(time, time2) {
    let tip = ''
    if (time != '' && time != '0.00') {
      tip = '(契) ' + time2 + ' (' + time + ')'
    }
    return tip
  }

  /**
   * 電話クリックイベント
   * @param tel 電話番号
   */
  telClick(tel) {
    let t = tel.replace(/-/g, '');
    t = t.replace(/ー/g, '');
    window.navigator['clipboard'].writeText(t);
  }

  /**
   * 清掃員名クリックイベント
   */
  staffDetailDialogOpen(event: any) {
    this.dialog4.open(StaffDetailDialogComponent, {
      data: {
        data: event
      }
    });
  }
}

export interface DialogData {
  any
}

@Component({
  selector: 'operation-dialog',
  templateUrl: './operation-dialog/operation-dialog.component.html',
  styleUrls: ['./operation-dialog/operation-dialog.component.less']
})
export class OperationDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}

@Component({
  selector: 'holiday-dialog',
  templateUrl: './holiday-dialog/holiday-dialog.component.html',
  styleUrls: ['./holiday-dialog/holiday-dialog.component.less']
})
export class HolidayDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}

@Component({
  selector: 'spot-dialog',
  templateUrl: './spot-dialog/spot-dialog.component.html',
  styleUrls: ['./spot-dialog/spot-dialog.component.less']
})
export class SpotDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
